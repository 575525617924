<template>
	<div
		class="product"
		:class="[
			`product--${product.id}`,
			imgLoaded && 'loaded',
			isPremium && 'premium',
			isVertical && 'vertical',
			isXmas && 'x-mas vertical',
			isChina && 'china vertical',
			isEmba && 'emba vertical',
			isEasterProduct && 'easter premium',
			isNewYear && 'new-year premium',
			isLightButtons && 'light-buttons',
			isHidden && 'hide',
		]"
		v-bind="$attrs"
		v-if="validItemQuantity"
	>
		<div
			class="product__wrapper"
			@click="
				openModal(
					isPremium || isHunterSet || isCombatKit || isTrampBike ? 'halloween' : 'pay'
				)
			"
		>
			<ShopProductPremium
				v-if="
					isPremium ||
					isHunterSet ||
					isCombatKit ||
					isTrampBike ||
					isNewYear ||
					isEasterProduct
				"
				:imgLoaded="imgLoaded"
				:price="itemPrice"
				:productId="product.id"
				:productOptions="productOptions"
				:productShare="productShare"
				@imgReady="onImgLoad"
			/>
			<template v-else>
				<div class="product__image">
					<img :src="img" :srcset="img2x" :alt="product.name" @load="onImgLoad" />
				</div>

				<div class="product__tag" v-if="isHit">
					<img
						src="/static/img/shop/tag-hit.png"
						srcset="/static/img/shop/tag-hit@2x.png 2x"
						alt="хит"
					/>
				</div>
				<div class="product__tag" v-if="isBestPrice">
					<img
						src="/static/img/shop/tag-price.png"
						srcset="/static/img/shop/tag-price@2x.png 2x"
						alt="хит"
					/>
				</div>
				<div class="product__content">
					<template v-if="isVertical">
						<div class="product__quantity __coins">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/coins_icon.png"
									srcset="@/assets/img/coins_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantity }}</span>
						</div>
						<div class="product__quantity __ghost">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/ghost.png"
									srcset="@/assets/img/ghost@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityGhost }}</span>
						</div>
					</template>
					<template v-else-if="isChina">
						<div class="product__quantity __bowl">
							<span>{{ itemQuantityBowls }}</span>
						</div>
						<div class="product__quantity __china_coins">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/сhinese_coin.png"
									srcset="@/assets/img/сhinese_coin@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityChina }}</span>
						</div>
					</template>
					<template v-else-if="isXmas">
						<div class="product__quantity __bowl">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/bowl_icon.png"
									srcset="@/assets/img/bowl_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityBowls }}</span>
						</div>
						<div class="product__quantity __cookie">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/cookie_icon.png"
									srcset="@/assets/img/cookie_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityCookies }}</span>
						</div>
					</template>
					<template v-else-if="isEmba">
						<div class="product__quantity __bowl">
							<span>{{ itemQuantityBowls }}</span>
						</div>
						<div class="product__quantity __disk">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/disketa_icon.png"
									srcset="@/assets/img/disketa_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityDisk }}</span>
						</div>
					</template>
					<template v-else-if="isEasterProduct">
						<div class="product__quantity __bowl">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/bowl_icon.png"
									srcset="@/assets/img/bowl_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityBowls }}</span>
						</div>
						<div class="product__quantity __cookie">
							<span class="product__quantity-pic">
								<img
									src="@/assets/img/cookie_icon.png"
									srcset="@/assets/img/cookie_icon@2x.png 2x"
									alt="coins"
								/>
							</span>
							<span>{{ itemQuantityCookies }}</span>
						</div>
					</template>
					<template v-else>
						<div class="product__quantity">{{ itemQuantity }}</div>
						<div class="product__bonus">{{ itemBonus }}</div>
					</template>
					<div class="product__price">{{ itemPrice }} <span>₽</span></div>
				</div>

				<div class="product__background">
					<template v-if="isVertical">
						<img
							class="product__background-desktop"
							src="@/assets/img/shopBgVertCaps.png"
							srcset="@/assets/img/shopBgVertCaps@2x.png 2x"
							alt="bg"
						/>
						<img
							class="product__background-mobile"
							src="@/assets/img/shopBgHorCaps.png"
							srcset="@/assets/img/shopBgHorCaps@2x.png 2x"
							alt="bg"
						/>
					</template>
					<template v-else-if="isChina">
						<img
							class="product__background-desktop"
							src="@/assets/img/shopBgChinaVert.png"
							srcset="@/assets/img/shopBgChinaVert@2x.png 2x"
							alt="bg"
						/>
						<img
							class="product__background-mobile"
							src="@/assets/img/shopBgChinaHor.png"
							srcset="@/assets/img/shopBgChinaHor@2x.png 2x"
							alt="bg"
						/>
					</template>
					<template v-else-if="isXmas">
						<img
							class="product__background-desktop"
							src="@/assets/img/shopBgXmasVert.png"
							srcset="@/assets/img/shopBgXmasVert.png 2x"
							alt="bg"
						/>
						<img
							class="product__background-mobile"
							src="@/assets/img/shopBgXmasHor.png"
							srcset="@/assets/img/shopBgXmasHor@2x.png 2x"
							alt="bg"
						/>
					</template>
					<template v-else-if="isEmba">
						<img
							class="product__background-desktop"
							src="@/assets/img/shopBgEmbaVert.png"
							srcset="@/assets/img/shopBgEmbaVert.png 2x"
							alt="bg"
						/>
						<img
							class="product__background-mobile"
							src="@/assets/img/shopBgEmbaHor.png"
							srcset="@/assets/img/shopBgEmbaHor@2x.png 2x"
							alt="bg"
						/>
					</template>
					<template v-else>
						<img
							src="@/assets/img/shopBgCaps.png"
							srcset="@/assets/img/shopBgCaps@2x.png 2x"
							alt="bg"
						/>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import {
	easterProduct,
	halloweenProduct,
	isNewYearProduct,
	startProduct,
	lightButtons,
	xMasProduct,
	chinaProduct,
	eMbaProduct,
} from "../../api/helpers"

export default {
	data() {
		return {
			imgLoaded: false,
		}
	},
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		productOptions() {
			let ret = []

			if (this.isNewYear) {
				ret = JSON.parse(this.product.item_data)?.items

				const temp = ret.slice(0)
				ret[0] = temp.find((f) => f.item_id === "ny_balls")
				ret[1] = temp.find((f) => f.item_id === "ny_cookie")
			}

			return ret
		},
		productShare() {
			let ret = ""

			if (this.product.discount && this.product.discount !== "0") {
				ret = this.product.discount + "%"
			}

			return ret
		},
		itemQuantity() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return +items[0].item_quantity
			} catch {
				return null
			}
		},
		itemQuantityGhost() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return +items[1].item_quantity
			} catch {
				return null
			}
		},
		itemQuantityBowls() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "ny_balls")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityDisk() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "emba_disk")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityChina() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "ch_coin")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityCookies() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "ny_cookie")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityElf() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "monopolist_elf")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityIce() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "ice_cream")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityCola() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "energy_drink_ny")?.item_quantity || null
			} catch {
				return null
			}
		},
		itemQuantityGluehwein() {
			try {
				const { items } = JSON.parse(this.product.item_data)
				return items.find((f) => f.item_id === "spiced_wine")?.item_quantity || null
			} catch {
				return null
			}
		},
		isHidden() {
			return this.product.ishide == 1
		},
		isPremium() {
			return this.itemQuantity === 1
		},
		isHunterSet() {
			return this.name === "Набор искателя"
		},
		isCombatKit() {
			return this.name === "Боевой комплект"
		},
		isTrampBike() {
			return this.name === "Мотоцикл Бродяга"
		},
		isVertical() {
			return halloweenProduct(this.product.id)
		},
		isXmas() {
			return xMasProduct(this.product.id)
		},
		isChina() {
			return chinaProduct(this.product.id)
		},
		isEmba() {
			return eMbaProduct(this.product.id)
		},
		start() {
			return startProduct(this.product.id)
		},
		isLightButtons() {
			return lightButtons(this.product.id)
		},
		isEasterProduct() {
			return easterProduct(this.product.id)
		},
		isNewYear() {
			return isNewYearProduct(this.product.id)
		},
		validItemQuantity() {
			return true
			// return DISPLAY_ITEMS.includes(this.itemQuantity)
		},
		isHit() {
			return +this.product.id === 4
		},
		isBestPrice() {
			return +this.product.id === 6
		},
		img() {
			return `/static/img/shop/product-${this.product.id}.png`
		},
		img2x() {
			return `/static/img/shop/product-${this.product.id}@2x.png 2x`
		},
		img_hover() {
			return `/static/img/shop/product-${this.product.id}-hover.png`
		},
		img2x_hover() {
			return `/static/img/shop/product-${this.product.id}@2x-hover.png 2x`
		},
		itemPrice() {
			return Number(this.product.price).toLocaleString()
		},
		itemBonus() {
			const val = this.product.bonus
			return parseInt(val) ? `Бонус +${+val}` : ""
		},
	},
	methods: {
		openModal(type) {
			this.setModal({
				name: type || "pay",
				price: +this.product.price,
				product_id: this.product.id,
				product_name: this.product.name,
				quantityGhost: this.itemQuantityGhost,
				quantityDisk: this.itemQuantityDisk,
				quantityChina: this.itemQuantityChina,
				quantityBowls: this.itemQuantityBowls,
				quantityCookies: this.itemQuantityCookies,
				quantityGluehwein: this.itemQuantityGluehwein,
				quantityCola: this.itemQuantityCola,
				quantityIce: this.itemQuantityIce,
				quantityElf: this.itemQuantityElf,
				quantity: this.itemQuantity,
				bonus: this.product.bonus,
			})
		},
		onImgLoad() {
			this.imgLoaded = true
			this.$emit("imgReady", this.product.id)
		},
		...mapMutations("ui", ["setModal"]),
	},
}
</script>

<style lang="scss" scoped>
.product {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-width: 1px;
	flex: 0 0 $col12;
	max-width: $col12;
	margin-bottom: 8px;
	padding: 0 8px;
	overflow: hidden;

	&.loaded {
		.product__background {
			min-height: 1px;
		}
	}

	&.hide {
		display: none !important;
	}

	.product__image.hover {
		z-index: 0;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		.product__image.hover {
			//opacity: 1;
		}
	}

	&__wrapper {
		position: relative;
		z-index: 1;
		margin: auto;
		max-width: 320px;
		text-align: center;
		cursor: pointer;
	}

	&__background {
		font-size: 0;
		min-height: 145px;

		img {
			width: 100%;
		}

		&-desktop {
			display: none;
		}
	}

	&__image {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 0;
		left: 0;
		top: 0;
		bottom: 0;
		width: 40%;
		padding: 2% 4%;

		.emba & {
			width: 48%;
		}

		img {
			max-width: 100%;
		}
	}

	&__tag {
		position: absolute;
		z-index: 3;
		bottom: 9%;
		left: 4%;
		width: 38%;
		pointer-events: none;

		img {
			max-width: 100%;
		}
	}

	&__content {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		bottom: 0;
		top: 13%;
		left: 40%;
		right: 5%;

		.emba & {
			top: 28%;
		}
	}

	&__quantity {
		font-family: $russoFont;
		color: $colorPrimary;
		font-size: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		.x-mas & {
			font-size: 28px;
		}

		&-pic {
			margin-right: 10px;
			width: 32px;
			height: 32px;

			img {
				max-width: 100%;
			}

			.x-mas & {
				img {
					max-height: 40px;
				}
			}

			.emba & {
				width: 40px;
			}
		}

		&.__ghost {
			color: #5fcbb6;
		}

		&.__coins {
			color: #d9a331;
		}

		&.__disk {
			color: #f0e4ba;
			font-size: 28px;
			margin-bottom: 10px;
		}

		&.__bowl {
			color: #f0e4ba;
		}

		&.__cookie {
			color: #d18539;
		}
	}

	&__bonus {
		font-family: $russoFont;
		font-size: 17px;
		color: #d9a331;
		text-transform: uppercase;
	}

	&__price {
		white-space: nowrap;
		margin: auto auto 20px;
		min-width: 65%;
		padding: 3px 10px;
		background: $colorAccent;
		font-family: $russoFont;
		font-size: 20px;
		transition: background 0.25s $ease, color 0.25s $ease;

		span {
			font-family: $baseFont;
			font-weight: 600;
		}
	}

	&--1 {
		.product__image {
			width: 42%;
			padding-left: 6%;
		}
	}

	&--2 {
		.product__image {
			padding-left: 8%;
			width: 43%;
		}
	}

	&--3 {
		.product__image {
			width: 43%;
		}
	}

	&--4 {
		.product__image {
			top: -10%;
			width: 42%;
		}
	}

	&--5 {
		.product__image {
			width: 47%;
		}
	}

	&--6 {
		.product__image {
			top: -15%;
			width: 45%;
		}
	}

	&.vertical {
		.product__price {
			margin-top: 5px;
			color: #35271e;
			background: #f0e4ba;
			border-radius: 3px;
		}
	}

	&.china {
		.product__content {
			top: 28%;
		}

		.product__image {
			left: 5%;
		}

		.product__price {
			margin-top: 25px;
			color: #35271e;
			background: #f0e4ba;
			border-radius: 3px;
		}

		.product__quantity {
			font-size: 28px;
			color: #f0e4ba;

			&-pic {
				margin: -2px 10px -2px 0;
				width: 30px;
				height: 30px;

				img {
					margin-left: -6px;
				}
			}
		}
	}

	&.emba {
		.product__price {
			color: #242429;
			background: #c77c2f;
		}
	}

	&.x-mas {
		.product__price {
			// border-radius: 0;
			margin-top: 8px;
		}
	}

	&.light-buttons {
		.product__price {
			color: #35271e;
			background: #f0e4ba;
			&:hover {
				background: white;
				color: $colorAccent;
			}
		}
	}

	&:hover {
		.product__price {
			background: white;
			color: $colorAccent;
		}
	}
}

@include rmin($sm) {
	.product {
		flex: 0 0 $col6;
		max-width: $col6;
		margin-bottom: 12px;
		padding: 0 14px;

		&.premium {
			flex-basis: $col12;
			max-width: $col12;
		}

		&.vertical {
			flex-basis: $col4;
			max-width: $col4;

			.product__content {
				bottom: 5%;
				top: auto;
			}

			.product__content,
			.product__image {
				left: 5%;
				right: 5%;
			}

			.product__image {
				top: 2%;
				width: auto;
				height: auto;
				bottom: auto;

				&::before {
					content: "";
					width: 100%;
					display: block;
					padding-bottom: 65%;
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-height: 100%;
				}
			}

			.product__price {
				margin-top: 10px;
			}

			&.china {
				.product__price {
					margin-top: 20px;
				}

				.product__image {
					top: 12%;

					img {
						max-height: 120%;
					}
				}

				.product__quantity {
					font-size: 32px;

					&-pic {
						margin: -2px 10px -2px 0;
						width: 40px;
						height: 40px;

						&.img {
							margin-left: -6px;
						}
					}
				}
			}

			&.emba {
				.product__image {
					top: 12%;
				}
			}
		}

		&__background {
			&-mobile {
				display: none;
			}

			&-desktop {
				display: block;
			}
		}

		&__wrapper {
			width: 100%;
			max-width: 100%;
		}

		&__quantity {
			font-size: 36px;

			&.__disk {
				font-size: 32px;
				margin-bottom: 0;
			}

			.x-mas & {
				font-size: 36px;
			}

			&-pic {
				margin: -2px 14px -2px 0;
				width: 48px;
				height: 48px;

				.emba & {
					margin-top: 10px;
				}

				.x-mas & {
					img {
						max-height: 40px;
					}
				}
			}
		}

		&__bonus {
			font-size: 20px;
		}

		&__price {
			font-size: 23px;
			border-radius: 4px;
			padding: 3px 10px;
		}
	}
}
</style>
